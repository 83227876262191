






























































import Vue from "vue";
import _ from 'lodash';
import notificationMixin from '../../mixin/notification';
import store from '../../store/shop';
import ShopAPI from '../../common/api/api_shop';
import { TranslateServiceBuilder } from '../../services/Translate/translateServiceBuilder';

const translate = TranslateServiceBuilder.Instance()._;
const api = new ShopAPI();

Vue.directive('enhance-defaulot-value', {
    bind: function (el) {
        el.addEventListener('focus', function() {
            const value = el.value;
            if (value == 0) {
                el.value = '';
            }
        });
        el.addEventListener('blur', function() {
            const value = el.value;
            if (value == '') {
                el.value = 0;
            }
        });
    }
});

export default Vue.extend({
  components: {
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
  },
  mixins: [
    notificationMixin,
  ],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      newTable: {  
        id: '',
        adult: '0',
        child: '0',
      },
      isLoading: false,
      errors: "",
    };
  },
  mounted() {
    api.set_token(store.state.token);
  },
  computed: {
    prefix() {
      return this.$route.params.prefix;
    },
    shoppix() {
      return this.$route.params.shoppix;
    },
  },
  methods: {  
    handleClickSubmit: function() {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        this.submitItem();
    },
    handleClickCloseEditor: function () {
        this.closeEditor();
    },
    closeEditor: function() {
        this.isLoading = false;
        this.inputInit();
        this.$emit('close');
    },
    inputInit: function() {
      this.newTable.id = '';
      this.newTable.adult = '0';
      this.newTable.child = '0';
    },
    redirectToOrder() {
      this.$router.push(`/${this.prefix}/${this.shoppix}/table-session/order/${this.newTable.id}/categories`);
    },
    
    submitItem: function () {
      let message = '';  
      api
        .table_sessions_quick({ 
          adult: this.newTable.adult,           
          child: this.newTable.child
        })
        .then((response) => {
          if (response.data.status !== 'success') {
            message = response.data.message || translate('hall-0046', '保存に失敗しました。');
            this.showErrorNotification(message);
            this.errors = message;
            this.isLoading = false;
            return;
          }            
          this.closeEditor();          
          this.newTable.id = response.data.data.table_session_id;
          this.redirectToOrder();
        })
        .catch((err) => {
            message = err.response.data.message || translate('hall-0046', '保存に失敗しました。');
            this.showErrorNotification(message);
            this.errors = message;
            this.isLoading = false;
        })
    }, 
  },
  filters: {
    setChecked: function (value) {
        if (value) {
            return "checked";
        }
    },
  },
})
